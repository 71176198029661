<template>
	<div>
		<table>
			<tr>
				<td>New Group Name</td>
			</tr>
			<tr>
				<td>
					<input type="text" class="name-input" v-model="group.name" placeholder="Group name" />
				</td>
			</tr>
		</table>
		<div
				 class="feed"
				 :style="`{'border-color':${feed.color}}`"
				 v-for="feed in feeds"
				 v-bind:key="feed.id">
			<div class="feedInfoRow">
				<div class="info">
					<input type="checkbox" class="subscribe" v-model="feed.subscribed" />
					<div class="feedName">{{ feed.name }}</div>
					<div class="feedDesc">{{ feed.desc }}</div>
				</div>
			</div>
			<input
						v-if="feed.defaultConfig != null"
						v-model="feed.config"
						type="text"
						class="txtInput"
						placeholder="Stock Ticker List (e.g. AAPL, TWTR, GOOG)"
					/>
		</div>
		<div class="footer">
			<button v-if="!group.id" class="goButton" @click="createGroup()">Create Group</button>
			<button v-if="group.id" class="goButton" @click="updateGroup()">Update Group</button>
		</div>
		<div class="footer-spacer" />
		<div class="bottom-spacer" />
	</div>
</template>

<script>
export default {
	name: "EditGroup",
	data() {
		return {
			group: {},
			feeds: []
		};
	},
	created() {
		this.loadData();
	},
	methods: {
		loadData: async function () {
			if(this.$route.params.id != "new") {
				this.group = await this.$services.getOne('groups', this.$route.params.id);
				this.loadFeeds(this.group.id);
			}
		},
		loadFeeds: function (groupID) {
			this.$services.getAll(`groups/${groupID}/feeds`).then(async feeds => {
				this.feeds = feeds;
				console.log("Feeds are", this.feeds)
			});
		},
		createGroup: function () {
			this.$services.createOne("groups", this.group).then(async (data) => {
				this.group = await this.$services.getOne('groups', data.id);
				this.loadFeeds(data.id)
			});
		},
		updateGroup: function () {
			console.log("Update group")
			this.$services.updateOne("groups", this.group.id, {group: this.group, feeds: this.feeds}).then((data) => {
				console.log("Update:", data)
				this.$router.push('/settings')
			});
		}
	}
}
</script>

<style scoped>
.header {
	margin: 10px 1vw 10px 1vw;
	font-size: 24px;
}

.name-input {
	font-size: 22px;
	height: 32px;
	color: black;
	background-color: white;
	border-bottom: 1px solid black;
	padding: 2px;
}

.users-input {
	text-align: center;
	font-size: 22px;
	height: 32px;
	color: black;
	background-color: white;
	border-bottom: 1px solid black;
	padding: 2px;
	width: 40px;
}

.feed {
	font-size: 20px;
	margin: 10px 1vw 10px 1vw;
	padding: 3px;
	background-color: #303030;
	border: 1px solid white;
	border-radius: 5px;
}

.feedInfoRow {
	display: block;
}

.subscribe {
	height: 22px;
	width: 22px;
	display: inline-block;
	vertical-align: top;
	padding: 3px;
}

.imgBox {
	display: inline-block;
	vertical-align: top;
	padding: 3px;
}

.info {
	display: inline-block;
}

.feedName {
	display: inline-block;
	font-size: 22px;
}

.feedDesc {
	font-size: 18px;
}

.txtInput {
	font-size: 18px;
	padding: 2px;
	border: 1px solid black;
	width: 98%;
	color: black;
}
</style>
